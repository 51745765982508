.Container-All-Card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding:5px;
}

.pet-card {
    background-color: #ffea74;
    /* background-color: #d0ff00; */
    border-radius: 50px;
    padding:10px;
    margin: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 350px;
    width: 250px;
}

.pet-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 225px;
}

.farm-card {
    background-color: #ffea74;
    /* background-color: #00ff95; */
    border-radius: 50px;
    padding:50px;
    margin: 25px;
    align-items: center;
    height: 350px;
    width: 90%;
    
}

.farm-title {
    display: flex;
    margin: auto;
    justify-content: space-evenly;
}

.farm-table {
    margin: auto;
}

.farm-table, th, td {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
}


.build-info {
    background-color: #b6ffb9;
    border-radius: 25px;
    /* padding:10px; */
    /* margin: 25px; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 185px;
    width: 225px;
}

.build-info,.extras th{
    /* font-size: 15px; */
    justify-content: space-around;
}
.build-info,.extras h5{
    /* font-size: 15px; */
    justify-content: center;
}

.table-headings {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    text-align: center;
}

.table-row {
    /* display: flex; */
    
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
    text-align: center;

}

.build-button {
    margin-top: 10px;
}