.navbar {
    background: linear-gradient(
      90deg,
      rgb(65, 65, 65) 35%,
      rgb(107, 107, 107) 65%
    );

    height: 100px;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-around; */
    font-size: 2rem;
    margin: auto;
    

  }

.navbar-items {
    color: azure;
    /* background-color: #fef1d2; */
    border-radius: 15px;
    padding: 0 0px 0 50px;
    /* margin: 35px; */

    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-around; */
    /* align-items: center; */
    /* width: 225px; */
}
.navbar-title {
}
.wallet-connect {
}